const colors = {
  orange: {
    600: "#EBC76E",
  },
  green: {
    500: "#b2cebb",
    600: "#92C0A5",
  },
  brown: {
    600: "#b07427",
  },
  blue: {
    600: "#7287f7",
  },
  gray: {
    800: "#1F2937",
  },
  "pct-pink": {
    600: "#F7E3D2",
  },
  "pct-olive": {
    600: "#D8C36F",
  },
  "pct-green": {
    600: "#027052",
  },
  "pct-red": {
    600: "#E2470A",
  },
};

export default colors;
