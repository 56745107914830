import { FeatureCollection, Point, Feature, LineString } from "geojson";
import {
  Map,
  Marker,
  GeoJSONSource,
  MapboxOptions,
  LngLatLike,
  Popup,
  NavigationControl,
  LineLayer,
  AnyLayer,
  AttributionControl,
  ScaleControl,
} from "mapbox-gl";
import axios from "axios";
import MapFunctions from "../map_functions";
import colors from "../../colors";
import { dateTimeText } from "../../helpers";
import { FocusControl } from "./focus_control";
import { SettingsControl } from "./settings_control";

class TrackerMap {
  public map: Map = {} as Map;
  private finalOptions: MapboxOptions;
  markers: Marker[] = [];
  trackingPoints: TrackingPoint[] = [];

  currentMarker: Marker | null = null;
  photoMarkers: Marker[] = [];

  private focused = true;

  private closePopup: any;

  lineLayers: Omit<LineLayer, "type" | "layout">[] = [];
  photoMarkerLayers: AnyLayer[] = [];
  locationMarkerLayers: AnyLayer[] = [];
  sources: DataSource[] = [];

  scale: ScaleControl;

  timezone = "UTC";

  private defaults: MapboxOptions = {
    container: "map-canvas",
    center: [0, 0] as LngLatLike,
    zoom: 3,
  };

  constructor(
    token: string,
    options?: Omit<MapboxOptions, "container"> & { container?: string },
    units?: "metric" | "imperial"
  ) {
    this.finalOptions = {
      ...this.defaults,
      ...options,
      accessToken: token,
    };

    this.scale = new ScaleControl({ unit: units });

    this.setupDefault();
  }

  setupDefault() {
    this.sources = [
      {
        name: "default",
        source: {
          type: "geojson",
          data: MapFunctions.initGeojson(),
        },
        data: MapFunctions.initGeojson(),
      },
    ];

    this.lineLayers.push({
      id: "default-border",
      source: "default",
      paint: {
        "line-color": "#FFFFFF",
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 5,
          10, 6,
          20, 9],
        "line-opacity": 0.9,
      },
    });

    this.lineLayers.push({
      id: "default",
      source: "default",
      paint: {
        "line-color": colors["pct-red"]["600"],
        // prettier-ignore
        "line-width": ["interpolate", ["linear"], ["zoom"],
          0, 1,
          10, 2,
          20, 5],
        "line-opacity": 1,
      },
    });
  }

  addArrowLayer(sourceName = "default") {
    this.map.loadImage("./images/icons/arrow.png", (err, img) => {
      if (err) {
        console.log(err);

        return;
      }

      this.map.addImage("arrow-sdf", img!, { sdf: true });

      const arrowLayer: AnyLayer = {
        id: "direction-indicators",
        type: "symbol",
        source: sourceName,
        layout: {
          "icon-image": "arrow-sdf",
          // prettier-ignore
          'icon-size': ["interpolate", ["linear"], ["zoom"],
            0, 1,
            10, 1,
            20, 1],
          "icon-allow-overlap": true,
          "icon-rotate": 90,

          "symbol-placement": "line",
          // prettier-ignore
          'symbol-spacing': ["interpolate", ["linear"], ["zoom"],
              0, 200,
              10, 200,
              20, 100],
          visibility: "visible",
        },
        paint: {
          "icon-color": colors["blue"][600],
          "icon-opacity": 1,
        },
      };

      this.map.addLayer(arrowLayer);
    });
  }

  load(callback?: Function) {
    this.map = new Map({
      ...this.finalOptions,
      style: import.meta.env.VITE_MAPBOX_STYLE,
      pitchWithRotate: false,
      dragRotate: false,
      touchPitch: false,
      attributionControl: false,
    });

    this.map.touchZoomRotate.disableRotation();

    const nav = new NavigationControl({
      showCompass: false,
    });
    this.map.addControl(nav, "top-right");

    this.map.addControl(this.scale);

    const settingsControlEl = document.getElementById("settings-ctrl-element");
    if (settingsControlEl) {
      const settingsControl = new SettingsControl({
        container: settingsControlEl,
      });
      this.map.addControl(settingsControl, "top-right");
    }
    const focusEl = document.getElementById("focus-element");
    if (focusEl) {
      const focus = new FocusControl({
        container: focusEl,
        onclick: () => this.moveToCurrent(),
      });
      this.map.addControl(focus, "top-right");
    }

    const attr = new AttributionControl();
    this.map.addControl(attr, "bottom-left");

    this.map.on("load", () => {
      document.getElementById("loader")?.remove();

      this.addSources();
      this.addRouteLayers();

      if (callback) callback(this);
    });
    this.map.on("dragstart", () => {
      this.unfocus();
    });
    this.map.on("zoomstart", () => {
      this.unfocus();
    });
  }

  clear() {
    this.markers.forEach((marker) => marker.remove());
    this.markers = [];
    this.trackingPoints = [];
  }

  async loadPoints() {
    this.trackingPoints = await this.getTrackingPoints();
    if (this.trackingPoints.length <= 0) {
      return;
    }
  }

  addSources() {
    this.sources.forEach((s) => this.map.addSource(s.name, s.source));
  }

  getSource(name = "default"): DataSource | undefined {
    return this.sources.find((s) => s.name === name);
  }

  updateSource(name = "default") {
    const source = this.map.getSource(name) as GeoJSONSource;
    const data = this.getSource(name)?.data;
    if (data) source.setData(data);
  }

  addLayer(options: Omit<LineLayer, "type" | "layout">) {
    this.map.addLayer({
      type: "line",
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      ...options,
    });
  }

  addRouteLayers() {
    this.lineLayers.forEach((layer) => {
      this.addLayer(layer);
    });
  }

  addMouseStates(name = "default") {
    this.map.on("mouseover", name, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    this.map.on("mouseleave", name, () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  addClickHandlers(name = "default") {
    this.map.on("click", name, (e: any) => {
      if (!e || !e.features) return;
      if (e.features[0].properties.last) return;

      const feature = e.features[0];
      const coordinates = feature.geometry.coordinates.slice();
      const description =
        feature.properties.description ||
        dateTimeText(feature.properties.timestamp, this.timezone);

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const popup = new Popup({ closeButton: false })
        .setLngLat(coordinates)
        .setHTML(description);
      popup.addClassName("text-center");
      popup.addTo(this.map);
    });
  }

  putMarker(sourceName: string, point: TrackingPoint, last = false) {
    const mapSource = this.map.getSource(sourceName) as GeoJSONSource;
    const source = this.sources.find((s) => s.name === sourceName);

    if (!source || !mapSource) return;

    source.data.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: point.location.coordinates,
      },
      properties: {
        ...point,
        description: dateTimeText(point.timestamp, this.timezone),
        last,
      },
    });

    mapSource?.setData(source.data);
  }

  putPhotoMarker(
    coords: LngLatLike,
    popupText: string,
    photo: string | undefined,
    options: {
      size?: string;
      style?: string;
      tag?: string;
    }
  ): Marker {
    const el = document.createElement("div");
    const photoSrc = photo ?? "images/logos/adventuretracking-logo.webp";
    el.className = `rounded-full border-white bg-white border-4 flex text-white cursor-pointer ${options.size} ${options.style}`;
    el.innerHTML = `<img class="rounded-full" src="${photoSrc}" />`;
    if (options.tag) {
      el.innerHTML += `<span class="absolute -bottom-4 left-0 right-0 w-auto rounded-md text-white bg-black text-center font-bold">${options.tag}</span>`;
    }

    const popup = new Popup({ closeButton: false, maxWidth: "600px" });
    popup.setHTML(popupText);

    // Add markers to the map.
    const marker = new Marker(el)
      .setLngLat(coords)
      .setPopup(popup)
      .addTo(this.map);

    this.markers.push(marker);

    return marker;
  }

  putCurrentMarker(
    point: TrackingPoint,
    device: TrackingDevice,
    ongoing = true
  ) {
    if (this.currentMarker) {
      this.moveMarker(this.currentMarker, point);
      return this.currentMarker;
    }

    const el = document.createElement("div");
    el.className =
      "rounded-full flex text-white cursor-pointer w-16 h-16 relative";
    let ping = '<span class="absolute flex w-16 h-16 z-0">';

    if (ongoing) {
      ping +=
        '<span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-pct-red-600 opacity-75"></span>';
    }

    ping +=
      `<span class="relative inline-flex rounded-full w-16 h-16 bg-white border-4 border-white"></span>
      </span>
    `.trim();
    el.innerHTML = ping;
    el.innerHTML += `<img
      class="rounded-full z-10 border-transparent border-4"
      src="${device.photo ?? "images/logos/adventuretracking-logo.webp"}"
      alt="${device.name}"
      title="${device.name}"
    />`;

    // Add markers to the map.
    const marker = new Marker(el)
      .setLngLat(point.location.coordinates)
      .setPopup(new Popup({ closeButton: false }))
      .addTo(this.map);
    this.moveMarker(marker, point);

    this.currentMarker = marker;

    return marker;
  }

  moveMarker(marker: Marker, point: Point) {
    marker.setLngLat(point.location.coordinates);
  }

  updatePopup(marker: Marker, point: Point, name?: string) {
    const popup = marker.getPopup();
    let html = "";
    if (name) {
      html += `<p class="mb-2 text-base"><strong>${name}</strong></p>`;
    }

    html += "<div>";
    html += dateTimeText(point.timestamp, this.timezone);
    html += "</div>";

    popup.addClassName("text-center");
    popup.setHTML(html);
  }

  putPhoto(photo: Photo) {
    const el = document.createElement("div");
    el.className =
      "rounded-full border-white bg-green-600 border-2 flex text-white cursor-pointer";
    el.innerHTML =
      '<svg class="w-4 m-auto" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M9 3h6l2 2h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4l2-2Zm3 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-2a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"/></svg>';
    el.style.width = `30px`;
    el.style.height = `30px`;
    el.onclick = (ev) => this.onPhotoMarkerClick(ev, photo);

    // Add markers to the map.
    const marker = new Marker(el)
      .setLngLat(photo.location.coordinates)
      .addTo(this.map);

    this.markers.push(marker);
  }

  putCheckpoint(checkpoint: Feature<Point>) {
    let svg: string;

    const el = document.createElement("div");
    el.className =
      "rounded-full border-white bg-green-600 border flex text-white cursor-pointer p-[3px]";
    switch (checkpoint.properties!.type) {
      case "start":
        svg =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M6 20.1957V3.80421C6 3.01878 6.86395 2.53993 7.53 2.95621L20.6432 11.152C21.2699 11.5436 21.2699 12.4563 20.6432 12.848L7.53 21.0437C6.86395 21.46 6 20.9812 6 20.1957Z"></path></svg>';
        break;
      case "finish":
        svg =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4Z"></path></svg>';
        break;
      default:
        svg =
          '<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M3 3h9.38a1 1 0 0 1 .9.55L14 5h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-6.38a1 1 0 0 1-.9-.55L12 16H5v6H3V3Z"/></svg>';
        break;
    }

    el.innerHTML = svg;
    el.style.width = `20px`;
    el.style.height = `20px`;

    // Add markers to the map.
    const marker = new Marker(el)
      .setLngLat(checkpoint.geometry.coordinates as [number, number])
      .addTo(this.map);

    this.markers.push(marker);
  }

  async getTrackingPoints(): Promise<TrackingPoint[]> {
    const result = await axios.get("/api/locations");

    return result.data;
  }

  zoomToBound(slice = 10) {
    const bounds = MapFunctions.getBoundingBox(this.trackingPoints);
    if (!bounds) return;

    this.map.fitBounds(bounds, {
      padding: 100,
      maxZoom: 10,
    });
  }

  moveToCurrent() {
    if (this.isFocused()) return;

    const pos = this.currentMarker?.getLngLat();
    if (pos) {
      this.map.easeTo({
        center: pos,
        duration: 500,
        zoom: 13,
      });
      this.focus();
    }
  }

  drawRoute() {
    const source = this.map.getSource("main-device-route") as GeoJSONSource;
    const coordinates = this.trackingPoints.map(
      (pt) => pt.location.coordinates
    );

    source?.setData(MapFunctions.geoJsonFromCoords(coordinates));
  }

  drawGeoJson(sourceName: string, geojson: Feature) {
    const source = this.getSource(sourceName);

    source?.data.features.push(geojson);
    this.updateSource(sourceName);
  }

  drawFeatureCollection(sourceName: string, geojson: FeatureCollection) {
    const source = this.getSource(sourceName);

    source!.data = geojson;
    this.updateSource(sourceName);
  }

  addFeature(sourceName: string, feature: Feature) {
    this.drawGeoJson(sourceName, feature);
  }

  focus() {
    this.focused = true;
    document.getElementById("focussed")?.classList.add("hidden");
    document.getElementById("unfocussed")?.classList.remove("hidden");
  }

  unfocus() {
    this.focused = false;
    document.getElementById("focussed")?.classList.remove("hidden");
    document.getElementById("unfocussed")?.classList.add("hidden");
  }

  isFocused() {
    return this.focused;
  }

  panTo(point: LngLatLike) {
    this.map.panTo(point);
  }

  onPhotoMarkerClick(ev: any, photo: Photo) {
    ev.stopPropagation();
    if (this.closePopup) {
      this.closePopup();
    }

    let popupElement: HTMLElement;
    popupElement = document.getElementById("map-popup")!;

    this.populatePopup(popupElement, photo);
  }

  async populatePopup(popupElement: HTMLElement, photo: Photo) {
    popupElement.classList.remove("hidden");
    popupElement.classList.add("flex");

    const contentEl = popupElement.querySelector("#popup-content");
    if (contentEl) {
      let html = `<img class="max-h-[80vh]" src="${photo.url}" />`;

      if (photo.text) {
        html += `<p class="pt-2">${photo.text}</p>`;
      }
      contentEl.innerHTML = html;
    }

    popupElement.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    });

    popupElement.addEventListener("touchend", (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    });
    const button = popupElement.querySelector("#popup-close");

    let fnRef = this.closePopup;
    this.closePopup = function (ev: MouseEvent) {
      document.removeEventListener("click", fnRef);
      document.removeEventListener("touchend", fnRef);

      button?.removeEventListener("click", fnRef);
      button?.removeEventListener("touchend", fnRef);

      if (popupElement) {
        popupElement.classList.add("hidden");
      }

      this.closePopup = undefined;
    };
    fnRef = this.closePopup;

    document.addEventListener("click", fnRef);
    document.addEventListener("touchend", fnRef);

    button?.addEventListener("click", fnRef);
    button?.addEventListener("touchend", fnRef);
  }
}

export default TrackerMap;
