import { DateTime } from "luxon";

export function dateTimeText(timestamp: string, timezone: string | undefined) {
  let html = `${DateTime.fromISO(timestamp)
    .setZone(timezone)
    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}`;
  const local = DateTime.fromISO(timestamp)
    .setZone(timezone)
    .toLocaleString(DateTime.TIME_SIMPLE);
  const userTime = DateTime.fromISO(timestamp).toLocaleString(
    DateTime.TIME_SIMPLE
  );

  html += "<br />";
  if (local != userTime) {
    html += `Local: ${local}`;
    html += "<br />";
    html += `You: ${userTime}`;
  } else {
    html += local;
  }

  return html;
}

export function formatDateElements() {
  const dates = document.querySelectorAll<HTMLElement>("[data-date]");

  dates.forEach((dateEl) => {
    const timestamp = dateEl.dataset.date;

    if (timestamp) {
      const parsedDate = DateTime.fromSeconds(Number.parseInt(timestamp));

      dateEl.innerHTML = parsedDate.toLocaleString(DateTime.DATETIME_FULL);
    }
  });
}
