import { Map } from "mapbox-gl";

export class SettingsControl {
  public _map: Map | undefined;
  public _container: HTMLElement;

  constructor(options: { container: HTMLElement }) {
    this._container = options.container;
  }

  onAdd(map: Map) {
    this._map = map;
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    return this._container;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}
